import React, { Component }  from 'react';
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { DefaultContainer } from '../components/Containers';



const Rules = () => {
  const homeFlag = require('../assets/MEX.png');
  const awayFlag = require('../assets/POL.png');
  return (
    <>
      <Navbar />
      <Header title="Regras"/>
      <DefaultContainer>
        <div className="py-4 px-8 bg-white shadow-lg rounded-lg my-2 pb-24">

          <p className="pt-2">O nosso bolão da equipe Bonzay está on-line.</p>
          <p className="pt-2">Este documento define as regras do Bolão Bonzay da copa do mundo 2022.</p>

          <h2 className="text-2xl font-bold text-gray-900 pt-8">Palpites</h2>
          <p className="pt-2">Você pode enviar seu palpite até 5 minutos antes do início do jogo.</p>
          <h2 className="text-2xl font-bold text-gray-900 pt-8">Pontuação</h2>
          <p className="pt-2">Se você Acertar o placar exato da partida, ganha <span className="font-bold">25 pontos</span>.</p>          
          <p className="pt-2">Se você Acertar o vencedor e o número de gols da equipe vencedora, ganha <span className="font-bold">18 pontos</span>.</p>
          <p className="pt-2">Se você Acertar o vencedor e a diferença de gols entre o vencedor e o perdedor, ganha <span className="font-bold">15 pontos</span>.</p>
          <p className="pt-2">Se você Acertar que a partida terminaria em empate, ganha <span className="font-bold">15 pontos</span>.</p>
          <p className="pt-2">Se você Acertar o time vencedor e o número de gols do time perdedor, ganha <span className="font-bold">12 pontos</span>.</p>
          <p className="pt-2">Se você Acertar apenas o vencedor da partida, ganha <span className="font-bold">10 pontos</span>.</p>
          <p className="pt-2">Se você Previu que o jogo seria um empate e não foi empate, ganha <span className="font-bold">4 pontos</span>.</p>
          <p className="pt-2">Outro tipo de palpite, <span className="font-bold">não ganha pontos</span>.</p>
          <p className="pt-2">Não palpitar, <span className="font-bold">não ganha pontos</span>.</p>
          <p className="pt-2">Esses pontos não são cumulativos em uma mesma partida.</p>
          <p className="pt-2">Por exemplo: se você acertar o placar da partida (25 pontos) e acertar o vencedor (10 pontos), esses dois critérios não serão somados. O que vai contar é o critério que vale mais pontos. Ou seja, o participante pode conseguir, no máximo, 25 pontos por partida.</p>

          <h2 className="text-2xl font-bold text-gray-900 pt-8">Placar</h2>
          <p className="pt-2">Será considerado somente o placar do tempo normal + prorrogação.</p>
          <p className="pt-2">Por exemplo: se um jogo terminar em 0 x 0 até o final da prorrogação, e for para os pênaltis, e o placar dos pênaltis for 5 x 4, o que vale é o placar de 0 x 0.</p>

          <h2 className="text-2xl font-bold text-gray-900 pt-8">Participantes Elegíveis</h2>
          <p className="pt-2">Toda a família Bonzay e Leandra Pet.</p>

          <h2 className="text-2xl font-bold text-gray-900 pt-8">Jogos Válidos</h2>
          <p className="pt-2">Serão válidos os palpites dos jogos do Brasil. <br />
            Fases de Grupo <br />
            Brasil vs Sérvia (24/11/22 as 16:00) <br />
            Brasil vs Suíça (28/11/22 as 13:00) <br />
            Brasil vs Camarões (02/12/22 as 16:00) <br />
            E mais quatros jogos do mata-mata até a grande final. Os palpites serão definidos para os jogos do Brasil, porém caso o Brasil seja eliminado, o organizador definirá rodada a rodada qual jogo palpitar, priorizando o caminho que o Brasil seguiria.<br />
            Você pode alterar o seu palpite quantas vezes quiser até 5 minutos antes do início de cada partida.<br />
            Serão 7 partidas computadas para o nosso Bolão.</p>



            <h2 className="text-2xl font-bold text-gray-900 pt-8">Desempate</h2>
            <p className="pt-2">Os critérios de desempate são nesta ordem:<br />
            1. O participante que acertou o placar exato do maior número de partidas;<br />
            2. Maior número de postagens no Instagram com #bonzaytecnologia e #confrabonzay2022.<br />
            3. Aquele que acertou, no maior número de partidas, o time ganhador e o número de gols que esse time fez naquele jogo;<br />
            4. O participante que acertou a diferença de gols entre o time A e o time B no maior número de partidas;<br />
            5. Quem acertou o time vencedor e o total de gols do time perdedor no maior número de partidas;<br />
            6. Maior número de partidas em que o participante acertou o time vencedor;</p>   

            <h2 className="text-2xl font-bold text-gray-900 pt-8">Eliminação  (Importantíssimo)</h2>
            <p className="pt-2">Será eliminado o participante que não comparecer a nossa confraternização.</p>


            <h2 className="text-2xl font-bold text-gray-900 pt-8">Prêmio</h2>
            <p className="pt-2">PIX de R$ 617,00</p>
            <p className="pt-2">Todos devem contribuir com R$ 10,00</p>

            <h2 className="text-2xl font-bold text-gray-900 pt-8">Organizador</h2>
            <p className="pt-2">Alan Jânio</p>

            <h2 className="text-2xl font-bold text-gray-900 pt-8">Inscrição</h2>
            <p className="pt-2">Comparecer na #confrabonzay2022 e marcar a @bonzay.com.br e #bonzaytecnologia</p>

            <p className="pt-2">Brasil rumo ao Hexa! 🏆🏆🏆🏆🏆🏆</p>
            <p className="pt-2">Bonzay fechado com o Brasil.</p>
                        
            

        </div>
      </DefaultContainer>
    </>
  );
}

export default Rules;
